@font-face {
  font-family: 'Titillium Web';
  src: local("Titillium Web"), url(fonts/TitilliumWeb-Black.woff2) format('woff2'), url(fonts/TitilliumWeb-Black.woff) format('woff'), url(fonts/TitilliumWeb-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Black Ops One';
  src: local("Black Ops One"), url(fonts/BlackOpsOne-Regular.woff2) format('woff2'), url(fonts/BlackOpsOne-Regular.woff) format('woff'), url(fonts/BlackOpsOne-Regular.svg) format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Oswald;
  src: local("Oswald"), url(fonts/Oswald-Bold.woff2) format('woff2') url(fonts/Oswald-Bold.woff) format('woff'),
    url(fonts/Oswald-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Conv_UnitedSansReg-Heavy;
  src: local("Conv_UnitedSansReg-Heavy"), url(fonts/UnitedSansReg-Heavy.woff) format('woff'),
    url(fonts/UnitedSansReg-Heavy.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Conv_UnitedSansReg-Light;
  src: local("Conv_UnitedSansReg-Light"), url(fonts/UnitedSansReg-Light.woff) format('woff'),
    url(fonts/UnitedSansReg-Light.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: 'Abril Fatface';
  src: local('Abril Fatface'), url('fonts/AbrilFatface-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/AbrilFatface-Regular.woff2') format('woff2'),
    url('fonts/AbrilFatface-Regular.woff') format('woff'),
    url('fonts/AbrilFatface-Regular.ttf') format('truetype'),
    url('fonts/AbrilFatface-Regular.svg#AbrilFatface-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FFHand';
  src: local("FFHand"), url('fonts/FFHand.eot?#iefix') format('embedded-opentype'),
    url('fonts/FFHand.woff2') format('woff2'),
    url('fonts/FFHand.woff') format('woff'),
    url('fonts/FFHand.ttf') format('truetype'),
    url('fonts/FFHand.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Interstate';
  src: local("Interstate"), url('fonts/InterstateBlackCondensed.eot?#iefix') format('embedded-opentype'),
    url('fonts/InterstateBlackCondensed.woff2') format('woff2'),
    url('fonts/InterstateBlackCondensed.woff') format('woff'),
    url('fonts/InterstateBlackCondensed.ttf') format('truetype'),
    url('fonts/InterstateBlackCondensed.svg#InterstateBlackCondensed') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shne Breit';
  src: local("Shne Breit"), url('fonts/SohneBreit-Dreiviertelfett.eot?#iefix') format('embedded-opentype'),
    url('fonts/SohneBreit-Dreiviertelfett.woff2') format('woff2'),
    url('fonts/SohneBreit-Dreiviertelfett.woff') format('woff'),
    url('fonts/SohneBreit-Dreiviertelfett.ttf') format('truetype'),
    url('fonts/SohneBreit-Dreiviertelfett.svg#SohneBreit-Dreiviertelfett') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titling Gothic FB Compressed';
  src: local("Titling Gothic FB Compressed"), url('fonts/TitlingGothicFBCompressed-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/TitlingGothicFBCompressed-Medium.woff2') format('woff2'),
    url('fonts/TitlingGothicFBCompressed-Medium.woff') format('woff'),
    url('fonts/TitlingGothicFBCompressed-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titling Gothic FB Compressed';
  src: local("Titling Gothic FB Compressed"), url('fonts/TitlingGothicFBCompressed-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/TitlingGothicFBCompressed-Regular.woff2') format('woff2'),
    url('fonts/TitlingGothicFBCompressed-Regular.woff') format('woff'),
    url('fonts/TitlingGothicFBCompressed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FIBA';
  src: local("FIBA"), url('fonts/FIBA-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/FIBA-Regular.woff2') format('woff2'),
    url('fonts/FIBA-Regular.woff') format('woff'),
    url('fonts/FIBA-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gist';
  src: local("Gist"), url('fonts/GistLight.woff2') format('woff2'),
    url('fonts/GistLight.woff') format('woff'),
    url('fonts/GistLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src: local("Century Gothic"), url('fonts/CenturyGothic.woff2') format('woff2'),
    url('fonts/CenturyGothic.woff') format('woff'),
    url('fonts/CenturyGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src: local("Century Gothic"), url('fonts/CenturyGothic-Bold.woff2') format('woff2'),
    url('fonts/CenturyGothic-Bold.woff') format('woff'),
    url('fonts/CenturyGothic-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'AgencyFB';
  src: local("AgencyFB"), url('fonts/AgencyFBBlack.woff2') format('woff2'),
    url('fonts/AgencyFBBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: local("Gotham"), url('fonts/GothamBlack.woff2') format('woff2'),
    url('fonts/GothamBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: local("Gotham"), url('fonts/Gotham-Thin.woff2') format('woff2'),
    url('fonts/Gotham-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fortima";
  src: local("Fortima"), url("fonts/Fortima-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Fortima-SemiBoldItalic.woff2") format("woff2"),
    url("fonts/Fortima-SemiBoldItalic.woff") format("woff"),
    url("fonts/Fortima-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Uniform Rnd";
  src: local("Uniform Rnd"), url("fonts/UniformRnd-Black.woff2") format("woff2"),
    url("fonts/UniformRnd-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Uniform Rnd";
  src: local("Uniform Rnd"), url("fonts/UniformRnd-Medium.woff2") format("woff2"),
    url("fonts/UniformRnd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Evogria";
  src: local("Evogria"), url("fonts/Evogria.eot?#iefix") format("embedded-opentype"),
    url("fonts/Evogria.woff2") format("woff2"),
    url("fonts/Evogria.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "PSG-Bold";
  src: local("PSG-Bold"), url("fonts/PSG-Bold.woff") format("woff"),
    url("fonts/PSG-Bold.ttf") format("truetype"),
    url("fonts/PSG-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PSG-Regular";
  src: local("PSG-Regula"), url("fonts/PSG-Regular.woff") format("woff"),
    url("fonts/PSG-Regular.ttf") format("truetype"),
    url("fonts/PSG-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PSG-Light";
  src: local("PSG-Light"), url("fonts/PSG-Light.woff") format("woff"),
    url("fonts/PSG-Light.ttf") format("truetype"),
    url("fonts/PSG-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PSG-inline";
  src: local("PSG-inline"), url("fonts/PSG-inline.woff") format("woff"),
    url("fonts/PSG-inline.ttf") format("truetype"),
    url("fonts/PSG-inline.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Parka-Black";
  src: local("Parka-Black"), url("fonts/Parka-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Parka-Medium";
  src: local("Parka-Medium"), url("fonts/Parka-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url("fonts/Poppins-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"), url("fonts/Poppins-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro'), url('fonts/DINPro.eot?#iefix') format('embedded-opentype'),
    url('fonts/DINPro.woff2') format('woff2'),
    url('fonts/DINPro.woff') format('woff'),
    url('fonts/DINPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro Condensed';
  src: local('DIN Pro Condensed'), url('fonts/din-pro-condensed-2.eot?#iefix') format('embedded-opentype'),
    url('fonts/din-pro-condensed-2.woff2') format('woff2'),
    url('fonts/din-pro-condensed-2.woff') format('woff'),
    url('fonts/din-pro-condensed-2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url('fonts/Raleway-Bold.woff2') format('woff2'),
    url('fonts/Raleway-Bold.woff') format('woff'),
    url('fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'), url('fonts/Raleway-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --toastify-toast-width: 500px;
  --toastify-toast-min-height: 200px;
  --toastify-toast-max-height: 400px;
}

.Toastify__toast-container--top-center .Toastify__toast-body {
  text-align: center;
}

video {
  width: 100%;
  height: 100%;

  &[data-reverse='true'] {
    transform: rotateY(180deg);
  }
}

:fullscreen video {
  min-width: 100% !important;
  width: 80vw !important;
  height: 100vh !important;
  margin-left: 10vw !important;
}

.portrait video,
.portrait svg,
.landscape div {
  display: none;
}

.landscape video,
.landscape svg,
.portrait div {
  display: block;
}

.portrait div {
  display: table-cell;
  vertical-align: middle;
  height: 25vh;
  width: 100vw;
  text-align: center;
  margin: 0 auto;
  padding: 1.5rem;
}

.active video {
  border: 10px solid var(--primary-color);
}

.grayscale video {
  filter: grayscale(1);
}

* {
  box-sizing: border-box
}

body,
html {
  width: 100%;
  height: 100%
}

#wrapper {
  width: 100%;
  height: 100%;
  overflow: scroll
}

html {
  font-size: 3.4vh
}

body {
  color: #fff;
  margin: 0;
  display: flex;
  background-color: var(--background-color);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 150vh !important;
  overflow: auto;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center
}

.flex-row>* {
  flex: none
}

.wrapper {
  width: 100%;
  height: 100%
}

header {
  width: 100%;
  min-height: 15vh;
  position: relative
}

header a {
  flex: none;
  margin-right: 1em;
  height: 90%;
  position: absolute;
  left: 0
}

header a.right {
  margin-right: 0;
  right: 0;
  left: auto
}

header a.center {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto
}

#header_logo.logo,
#header_logo2.logo,
header a .logo {
  text-align: center;
  margin: 0 auto !important;
  max-width: 250px !important;
  max-height: 200px !important;
  padding: 25px;
}

h1 {
  flex: none;
  margin: auto;
  font-size: 2rem;
  line-height: 1.5rem;
  line-height: 0;
  text-transform: uppercase;
  color: var(--fourth-color);
  color: var(--primary-color);
  text-shadow: 3px 1px 2px var(--secondary-color, #666);
  cursor: pointer;
  max-width: 100vw;
  text-align: center;
  font-style: italic
}

body,
h1,
html,
span.sonometer,
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  font-family: var(--font-family, "Titillium Web", sans-serif) !important;
}

main {
  overflow: hidden;
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%
}

main .content {
  position: absolute;
  top: 0;
  right: 15%;
  left: 15%;
  bottom: 0;
  padding: 2vh;
  z-index: 4
}

main .strip {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: .8
}

.title h2 {
  flex: none;
  font-size: 2rem;
  line-height: 2.75rem;
  font-weight: lighter;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  min-height: 150px;
  color: var(--primary-color)
}

.title h3 {
  flex: none;
  font-size: 2rem;
  line-height: 2.75rem;
  font-weight: lighter;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0
}

#home,
#player .visitor h2,
.card .main-splash,
.content .stats .bar .lbl,
.content .stats .subtitle,
.content .stats .title,
.content .teams,
.game .answer .label,
.game .answer b,
.game .disclaimer,
.game .question,
.quiz .answer .label,
.quiz .answer b,
.quiz .disclaimer,
.quiz .question,
.square h2,
.square p,
.title h2,
.title h3,
body#player p,
.css-qfso29-MuiTypography-root-MuiDialogContentText-root,
h5 {
  font-family: var(--secondary-font-family, "Oswald", sans-serif) !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
}

.content {
  color: var(--secondary-color)
}

.strip .title img {
  box-sizing: border-box
}

#home {
  font-size: .8rem;
  display: block
}

#player .visitor h2 {
  padding-top: 30px
}

#player .visitor h2,
body#player p {
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--primary-color)
}

#home main {
  background: #333;
  padding: 20px;
  overflow: scroll
}

#home main h2 {
  margin: revert;
  line-height: 1;
  font-size: 1rem
}

#home a {
  color: #fff;
  text-decoration: none
}

#home a:hover {
  color: #ccc;
  text-decoration: underline
}

@media only screen and (max-width:768px) {

  a.back,
  a.login,
  a.logout,
  a.wall {
    max-width: 85%
  }

  #player .part {
    padding: 5px !important
  }

  header {
    min-height: 5.5vh
  }

  header a.right {
    right: 20px;
    display: flex;
    justify-content: center
  }

  h1 {
    font-size: .85rem
  }

  .strip .title img {
    max-width: 350px
  }

  .title h2,
  .title h3 {
    font-size: 1.5rem;
    line-height: 1.75rem
  }

  .desktop-only {
    display: none
  }

  .game .disclaimer span,
  .game .question,
  .quiz .disclaimer span,
  body#betting h1,
  body#quiz .quiz .answer,
  body#quiz .quiz .disclaimer,
  body#quiz .quiz .question,
  body#quiz h1 {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important
  }
}

hr {
  border: 2px solid var(--secondary-color)
}

#fader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  pointer-events: none;
  background: #fff;
  animation-duration: .3s;
  animation-timing-function: ease-in-out
}

@keyframes fade-out {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes fade-in {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

#fader.fade-out {
  opacity: 0;
  animation-name: fade-out
}

#fader.fade-in {
  opacity: 1;
  animation-name: fade-in
}

.responsive-image {
  margin: 0 auto;
  text-align: center
}

.responsive-image img {
  max-height: 350px;
  max-width: 350px
}

.content .stats .home .lbl,
.content .stats .visitor .lbl {
  padding-top: .25rem;
  padding-bottom: .25rem;
  color: var(--background-color)
}

body[d-team_home=france] header h1 {
  font-family: Parka-Black, Arial, sans-serif
}

body.psghand .content .stats .title,
body[d-team_home=france] .content .stats .bar .lbl,
body[d-team_home=france] .content .stats .subtitle,
body[d-team_home=france] .content .teams .home,
body[d-team_home=france] .content .teams .visitor,
body[d-team_home=france] .quiz .answer .label,
body[d-team_home=france] .quiz .answer b,
body[d-team_home=france] .quiz .disclaimer,
body[d-team_home=france] .quiz .question,
body[d-team_home=france]#home,
body[d-team_home=france]#player .visitor h2,
body[d-team_home=france]#player p {
  font-family: Parka-Medium, Oswald, sans-serif
}

#quiz .wrapper {
  z-index: 2
}

#betting main .strip>.title,
#player header,
#player main .strip>.title,
#quiz header,
#quiz main .strip>.title {
  background-color: transparent
}

#stats header {
  background-color: var(--background-color) !important
}

#versus.limogdemoes main .strip>.logo.home,
.demo #fader,
.demo .content .stats .stat .home .bar,
.demo main .strip>.home.image,
.demo main .strip>.logo.home,
.demo main .strip>.title {
  background-color: #000
}

.demo #fader,
.demo .content .stats .visitor .bar {
  color: #000
}

.demo .content .stats .visitor .bar {
  background-color: #fff
}

body,
body#quiz,
header,
main .strip>.title {
  background-repeat: no-repeat !important;
  background-size: cover !important
}

body#player {
  background-color: var(--background-color) !important
}

#stats main .strip>.title {
  background-color: unset !important
}

#versus main .strip>.logo.home,
body #player main .strip>.home.image,
body main .strip>.home.image,
body main .strip>.logo.home,
body#stats main .strip>.logo.home {
  background-color: var(--primary-color) !important
}

body #player,
body #player main .strip>.logo,
body#stats main .strip>.logo.visitor,
body#stats main .strip>.visitor.image,
form button {
  background-color: var(--third-color) !important
}

body#stats main .strip>.logo.visitor {
  z-index: 4
}

body#stats main .strip>.logo.home {
  z-index: 3;
  background-position: center left
}

body .content .stats .stat .home .lbl {
  color: var(--primary-color)
}

body .content .stats .stat .away .lbl {
  color: var(--secondary-color)
}

.strip .card span,
body#player p strong {
  color: var(--fourth-color)
}

body .content .stats .home .bar {
  color: var(--primary-color) !important;
  background-color: var(--background-color) !important
}

body .content .stats .visitor .bar {
  color: var(--third-color) !important;
  background-color: var(--primary-color) !important
}

.card .main-splash,
.game .disclaimer {
  content: "";
  display: table;
  clear: both;
  font-style: italic
}

.card .splash-logo img,
.game .disclaimer img {
  max-width: 250px
}

.game .disclaimer span.podium1,
.game .disclaimer span.podium2,
.game .disclaimer span.podium3 {
  clear: both;
  display: block;
  text-align: left
}

.game .disclaimer span.podium2 {
  font-size: 2rem;
  line-height: 2.25rem
}

.game .disclaimer span.podium3 {
  font-size: 1.5rem;
  line-height: 1.75rem
}

.game .podium-logo {
  margin-top: 10%
}

.card .splash,
.card .splash-logo,
.game .podium,
.game .podium-logo {
  width: 50%;
  display: inline-block
}

.game .podium {
  vertical-align: top;
  margin-top: 10%;
  padding-left: 50px
}

body[d-team_home=bshop] header {
  height: 10vh
}

body[d-team_home=bshop] #header_logo {
  padding: 10px
}

#switcher {
  display: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  margin-bottom: 1rem
}

body.form #switcher {
  display: table
}

#switcher li {
  list-style-type: none;
  float: left;
  margin: 5px
}

#switcher li a {
  color: var(--primary-color);
  text-decoration: none
}

#switcher img {
  width: 50px;
  border: 5px solid var(--primary-color);
  border-radius: 25px
}

.blink {
  animation-duration: 1s;
  animation-name: blink;
  animation-iteration-count: infinite;
  transition: none
}

@keyframes blink {
  0% {
    opacity: 1
  }

  40% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

#transition {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 99999;
  background-color: #000;
  text-align: center;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%
}

#transition img {
  max-width: 100%;
  height: 100vh;
  margin: 0 auto
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.disclaimer {
  padding: .5rem
}

a.back,
a.login,
a.logout {
  margin: 0 auto !important;
  color: var(--fourth-color, #fff) !important;
  border: 5px solid var(--fourth-color, #fff) !important;
  background: var(--primary-color, #666) !important;
  max-width: 50%;
  font-size: .85rem;
  line-height: 1rem;
  text-align: center;
  display: block;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 700;
  font-family: var(--font-family, "Titillium Web", sans-serif);
  background: 0 0;
  padding-top: 10px !important;
  box-shadow: 3px 1px 2px var(--background-color, #000);
  padding-bottom: 10px !important;
  text-decoration: none !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important
}

#notice {
  color: var(--secondary-color);
  font-size: 1rem;
  line-height: 1rem;
  max-width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto
}

.error-message {
  display: none;
  margin: 0 auto;
  text-align: center;
  color: var(--secondary-color);
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 1rem
}

.required:after {
  content: " *";
  color: red
}

#modules li button:before {
  margin-left: -10px
}

#modules li button.external:before {
  content: "\01F517"
}

#modules li button.betting:before,
#modules li button.quiz:before,
#modules li button.quizb:before,
#modules li button.quizc:before {
  content: "\01F381"
}

#modules li button.scratch:before {
  content: "\01F0CF"
}

#modules li button.fanwall:before {
  content: "\01F4F7"
}

#modules li button.polling-mvp:before {
  content: "\01F3C6"
}

#modules li button.swipe-bet:before {
  content: "\01F3B2"
}

#modules li button.video-game:before {
  content: "\01F3B0"
}

#modules li button.predictor:before {
  content: "\01F575"
}

#modules li button.lottery:before {
  content: "\01F3B2"
}

body {
  color: var(--primary-color, #fff);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 0;
  flex-direction: column
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important
}

header {
  display: flex;
  width: 100vw;
  flex: none
}

header .logo {
  text-align: center;
  margin: 0 auto !important;
  max-width: 350px !important;
  max-height: 350px !important;
  padding: 50px
}

.top .left,
footer .left {
  width: 50%;
  height: 15px
}

.top .right,
footer .right {
  width: 50%;
  height: 15px
}

main {
  display: flex;
  flex: 1 0 0;
  position: relative
}

section {
  display: flex;
  position: absolute;
  left: 0;
  pointer-events: none;
  min-width: 100vw;
  min-height: 100%;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  opacity: 0
}

section.show {
  pointer-events: initial;
  opacity: 1
}

.register {
  padding: 0 1.4rem
}

.register form {
  width: 100%;
  position: relative;
  padding-bottom: 50px
}

.register form.locked::before {
  content: "";
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.register form.locked button,
.register form.locked label {
  opacity: .4
}

.register .logo2 {
  display: block;
  width: 25%;
  margin: auto
}

.register .title {
  text-align: center;
  margin: -.3rem auto 1.6rem auto;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 2.25rem;
  font-weight: 400;
  padding: 0;
  color: var(--primary-color, #fff)
}

form button,
form label {
  font-size: 1rem;
  text-align: center;
  display: block;
  margin: auto;
  margin-top: 1em;
  transition: opacity .3s
}

form .label,
form label {
  font-size: 1.5em;
  line-height: 1.25em;
  margin-bottom: .5em;
  text-transform: uppercase
}

form .disclaimer .label {
  font-size: 1.25em
}

form button,
form input,
form select,
form textarea {
  font-size: 1.5rem;
  padding: .3em;
  color: #000;
  box-shadow: 3px 1px 2px var(--background-color, #000);
  box-shadow: 3px 1px 2px #000
}

form input,
form select,
form textarea {
  text-align: center;
  width: 90%;
  border-radius: .4em;
  text-transform: uppercase;
  border: 5px solid var(--primary-color, #fff)
}

form input[type=checkbox] {
  width: auto;
  height: 1.5rem;
  width: 1.5rem;
  float: left
}

form button {
  margin: 1.6rem auto 1.4rem auto;
  font-weight: 700;
  border-radius: .4em;
  border-style: none;
  cursor: pointer
}

.register .disclaimer {
  font-size: .6rem;
  text-align: center
}

.pagination .form,
.register .disclaimer a,
a.wall,
form a.back,
main a.back {
  margin: 0 auto !important;
  color: var(--fourth-color, #fff) !important;
  border: 5px solid var(--fourth-color, #fff) !important;
  background: var(--primary-color, #666) !important;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  display: block;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 700;
  font-family: var(--font-family, "Titillium Web", sans-serif);
  background: 0 0;
  padding-top: 10px !important;
  box-shadow: 3px 1px 2px var(--background-color, #000);
  box-shadow: 3px 1px 2px #000;
  padding-bottom: 10px !important;
  text-decoration: none !important;
  margin-top: 25px !important
}

.scratch-game3 a,
a.wall,
form a.back,
main a.back {
  max-width: 50%
}

a.wall {
  color: var(--primary-color, #fff) !important;
  background: var(--fourth-color, #fff) !important;
  border: 5px solid var(--primary-color, #fff) !important
}

.scratch-game3 a,
main a.back {
  margin: 0 auto !important;
  color: var(--fourth-color, #fff) !important;
  border: 5px solid var(--fourth-color, #fff) !important;
  background: var(--primary-color, #fff) !important;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  display: block;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 700;
  font-family: var(--font-family, "Titillium Web", sans-serif);
  background: 0 0;
  padding-top: 10px !important;
  box-shadow: 3px 1px 2px var(--background-color, #000);
  box-shadow: 3px 1px 2px #000;
  padding-bottom: 10px !important;
  text-decoration: none !important;
  margin-top: 25px !important
}

.scratch-game3 a {
  background: var(--fourth-color, #fff) !important;
  border: 5px solid var(--primary-color, #fff) !important;
  color: var(--primary-color, #fff) !important
}

.register.rules {
  opacity: 1;
  width: 80%;
  margin: 0 auto;
  font-size: .75rem
}

.remote {
  transform: scale(.8);
  opacity: 0;
  transition: transform .3s cubic-bezier(.25, .46, .45, .94), opacity .3s cubic-bezier(.25, .46, .45, .94);
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: none
}

.remote.show {
  transform: scale(1);
  opacity: 1;
  padding: 40px;
  display: flex
}

.remote.game.show {
  padding: 0
}

.remote button {
  font-size: 1.5rem;
  padding: .2em .5em;
  border-radius: .25em;
  border-style: none;
  font-weight: 700;
  flex: none;
  color: var(--secondary-color, #666);
  background: 0 0
}

@media (min-width:768px) {

  .scratch-game3 a,
  a.wall,
  form a.back,
  main a.back {
    max-width: calc(100%/3)
  }
}

@media (max-width:768px) {
  body {
    background-size: contain !important;
    min-height: 150vh !important;
  }

  .remote button {
    background: 0 0 !important;
    padding: 0;
    width: 100%
  }

  .pagination .form,
  .scratch-game3 a,
  form a.back,
  main a.back {
    width: 50%;
    clear: both
  }
}

.remote button b,
body .remote button span {
  font-size: 1.5rem;
  line-height: 2rem;
  padding: .2em .5em;
  float: left;
  width: 15%
}

body[d-broadcaster_code=hpo] .remote button b,
body[d-broadcaster_code=hpo] .remote button span {
  font-family: "Shne Breit", Arial, sans-serif !important
}

.remote button b {
  color: var(--secondary-color, #666) !important;
  background: var(--third-color, #666) !important
}

body .remote button span {
  color: var(--fifth-color, #666) !important;
  background: var(--fourth-color, #fff) !important;
  text-transform: uppercase;
  font-weight: 400;
  width: 85%;
  text-align: left
}

.wait {
  overflow: hidden;
  transition: opacity .3s cubic-bezier(.25, .46, .45, .94)
}

.wait .wrapper {
  transform: scale(1.4);
  transition: opacity .3s cubic-bezier(.25, .46, .45, .94)
}

.wait.show .wrapper {
  transform: scale(1)
}

.wait .message {
  padding: 0 1em;
  margin-top: 1em;
  height: 25%;
  text-align: center
}

.end {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1em
}

.end .message {
  max-width: 75%;
  margin: 0 auto;
  font-weight: 700;
  text-transform: uppercase
}

.message,
.message1,
.message2 {
  display: none
}

.message.show,
.message1.show,
.message2.show {
  display: block;
  text-align: center;
  max-width: 80%
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--secondary-color, #666);
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0)
  }

  50% {
    -webkit-transform: scale(1)
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0)
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1)
  }
}

form button {
  color: var(--primary-color, #fff) !important;
  background: var(--fourth-color, #fff) !important;
  text-transform: uppercase
}

.betting .label,
.betting .remote label {
  color: var(--third-color, #666) !important;
  background: var(--secondary-color, #666) !important;
  width: 100%
}

.betting .end .message,
.betting .register .disclaimer,
.betting form .disclaimer label,
.betting form .label,
form .label,
form label {
  font-style: italic;
  color: var(--secondary-color, #666) !important
}

.betting .remote .question,
.betting form label {
  color: var(--primary-color, #fff) !important
}

.betting .remote button,
.register .disclaimer,
.remote button b,
body,
body .remote button span,
form button {
  font-family: var(--secondary-font-family, "Oswald", sans-serif)
}

h1 {
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 400;
  color: var(--primary-color, #fff) !important;
  margin: 0;
  padding: 0;
  display: none
}

.register .title,
h1 {
  font-family: var(--font-family, "Titillium Web", sans-serif);
  text-shadow: 3px 1px 2px var(--secondary-color, #666);
  font-style: italic
}

body[d-broadcaster_code=ffh] .register .title {
  font-size: 2rem;
  line-height: 1.75rem
}

body[d-broadcaster_code=ffh] .remote button b,
body[d-broadcaster_code=ffh] .remote button span {
  line-height: 1.5rem
}

body[d-broadcaster_code=fiba3x3],
body[d-broadcaster_code=fiba3x3] .register .disclaimer,
body[d-broadcaster_code=fiba3x3] form button,
body[d-broadcaster_code=fiba3x3] h1,
body[d-broadcaster_code=fiba],
body[d-broadcaster_code=fiba] .content,
body[d-broadcaster_code=fiba] .register .disclaimer,
body[d-broadcaster_code=fiba] .register .title,
body[d-broadcaster_code=fiba] form button,
body[d-broadcaster_code=fiba] h1 {
  font-family: Conv_UnitedSansReg-Heavy, Arial, sans-serif !important
}

body[d-broadcaster_code=hpo],
body[d-broadcaster_code=hpo] .content,
body[d-broadcaster_code=hpo] .register .disclaimer,
body[d-broadcaster_code=hpo] .register .title,
body[d-broadcaster_code=hpo] form button,
body[d-broadcaster_code=hpo] h1 {
  font-family: "Shne Breit", Arial, sans-serif !important
}

body .quiz .remote button {
  background: var(--fourth-color, #fff);
  color: var(--secondary-color, #666)
}

body .betting .remote button b {
  color: var(--secondary-color, #666);
  font-style: italic;
  float: left
}

body .remote .question {
  text-align: center
}

body .remote .image img {
  max-width: 100%;
  max-height: 250px
}

body[d-team_home=france],
body[d-team_home=france] .register .disclaimer {
  font-family: Parka-Medium, Oswald, sans-serif
}

body[d-team_home=france] form button {
  font-family: Parka-Black, Oswald, sans-serif
}

body {
  background-color: var(--background-color, #000);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100vw !important
}

form.error .error-message {
  display: block;
  text-align: center;
  margin: 0 auto
}

.betting form.error .error-message {
  color: var(--primary-color, #fff) !important
}

body[d-broadcaster_code=bcl],
body[d-broadcaster_code=bcl] .register .disclaimer,
body[d-broadcaster_code=bcl] .register .title,
body[d-broadcaster_code=bcl] .remote button,
body[d-broadcaster_code=bcl] h1 {
  font-family: Fortima, Oswald, sans-serif
}

body[d-broadcaster_code=icc],
body[d-broadcaster_code=icc] .register .disclaimer,
body[d-broadcaster_code=icc] .register .title,
body[d-broadcaster_code=icc] .remote button,
body[d-broadcaster_code=icc] h1 {
  font-family: FIBA, Oswald, sans-serif
}

body[d-team_home=adequat],
body[d-team_home=adequat] .register .disclaimer,
body[d-team_home=adequat] .register .title,
body[d-team_home=adequat] .remote button,
body[d-team_home=adequat] h1 {
  font-family: "Century Gothic", Oswald, sans-serif
}

body[d-team_home=adequat] form button {
  font-family: Gist, Oswald, sans-serif
}

body[d-team_home=adequat] .remote button {
  min-width: 120px
}

.strip {
  display: block;
  margin: 0 auto
}

body.quiz a.back {
  display: block;
  text-align: center;
  margin: 0 auto
}

body[d-broadcaster_code=fiba].quiz header a.right {
  right: 100px !important
}

.main-content {
  position: absolute;
  z-index: 9999
}

.main-content main {
  margin-top: 200px
}

.lottery svg {
  margin: auto;
  display: block;
  z-index: 1;
  position: absolute;
  background-size: cover;
  top: 100px
}

#headline {
  font-size: 4rem;
  line-height: 3rem;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center
}

#text1,
#text2 {
  text-align: justify
}

.lottery section.end {
  max-width: 85%;
  min-width: 85%;
  margin: 0 auto;
  text-align: center;
  display: block;
  left: 3.5%;
  font-size: 2rem;
  line-height: 1.85rem
}

@media (min-width:1024px) {
  .lottery section.screen {
    display: none !important
  }

  .lottery section.screen,
  .lottery section.screen.show {
    z-index: 9999;
    opacity: 1;
    background: var(--background-color, #000);
    display: block !important
  }

  .lottery section.screen p,
  .lottery section.screen.show p {
    padding-top: 5%;
    max-width: 85%;
    min-width: 85%;
    margin: 0 auto;
    text-align: center;
    left: 7.5%
  }
}

@media (max-width:1024px) {
  .lottery section.screen {
    display: none !important
  }
}

#message2 {
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  font-size: 2.5rem;
  line-height: 2.5rem
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0)
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg)
  }

  20% {
    transform: translate(-3px, 0) rotate(1deg)
  }

  30% {
    transform: translate(3px, 2px) rotate(0)
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg)
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg)
  }

  60% {
    transform: translate(-3px, 1px) rotate(0)
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg)
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg)
  }

  90% {
    transform: translate(1px, 2px) rotate(0)
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg)
  }
}

label.authenticate,
label.authenticate div,
label.authenticate input {
  height: 0;
  visibility: hidden;
  border: 0;
  padding: 0;
  margin: 0
}

.remote {
  overflow: hidden
}

.card {
  margin-top: 50%
}

#partner_logo {
  max-width: 100%
}

header .logo.mobile {
  display: none
}

#guidelines {
  color: var(--secondary-color, #666);
  text-align: center
}

.betting .register .label,
.scratch .register .label {
  background-color: unset !important;
  font-size: 1em !important
}

body[d-broadcaster_code=asg] button[data-value="0"] b,
body[d-broadcaster_code=asg] button[data-value="1"] b,
body[d-broadcaster_code=asg] button[data-value="10"] b,
body[d-broadcaster_code=asg] button[data-value="11"] b,
body[d-broadcaster_code=asg] button[data-value="12"] span,
body[d-broadcaster_code=asg] button[data-value="13"] span,
body[d-broadcaster_code=asg] button[data-value="14"] span,
body[d-broadcaster_code=asg] button[data-value="15"] span,
body[d-broadcaster_code=asg] button[data-value="16"] span,
body[d-broadcaster_code=asg] button[data-value="17"] span,
body[d-broadcaster_code=asg] button[data-value="18"] span,
body[d-broadcaster_code=asg] button[data-value="19"] span,
body[d-broadcaster_code=asg] button[data-value="2"] b,
body[d-broadcaster_code=asg] button[data-value="20"] span,
body[d-broadcaster_code=asg] button[data-value="21"] span,
body[d-broadcaster_code=asg] button[data-value="22"] span,
body[d-broadcaster_code=asg] button[data-value="23"] span,
body[d-broadcaster_code=asg] button[data-value="3"] b,
body[d-broadcaster_code=asg] button[data-value="4"] b,
body[d-broadcaster_code=asg] button[data-value="5"] b,
body[d-broadcaster_code=asg] button[data-value="6"] b,
body[d-broadcaster_code=asg] button[data-value="7"] b,
body[d-broadcaster_code=asg] button[data-value="8"] b,
body[d-broadcaster_code=asg] button[data-value="9"] b {
  color: #66b1d7 !important;
  background: #fecb00 !important
}

body[d-broadcaster_code=asg] button[data-value="0"] span,
body[d-broadcaster_code=asg] button[data-value="1"] span,
body[d-broadcaster_code=asg] button[data-value="10"] span,
body[d-broadcaster_code=asg] button[data-value="11"] span,
body[d-broadcaster_code=asg] button[data-value="12"] b,
body[d-broadcaster_code=asg] button[data-value="13"] b,
body[d-broadcaster_code=asg] button[data-value="14"] b,
body[d-broadcaster_code=asg] button[data-value="15"] b,
body[d-broadcaster_code=asg] button[data-value="16"] b,
body[d-broadcaster_code=asg] button[data-value="17"] b,
body[d-broadcaster_code=asg] button[data-value="18"] b,
body[d-broadcaster_code=asg] button[data-value="19"] b,
body[d-broadcaster_code=asg] button[data-value="2"] span,
body[d-broadcaster_code=asg] button[data-value="20"] b,
body[d-broadcaster_code=asg] button[data-value="21"] b,
body[d-broadcaster_code=asg] button[data-value="22"] b,
body[d-broadcaster_code=asg] button[data-value="23"] b,
body[d-broadcaster_code=asg] button[data-value="3"] span,
body[d-broadcaster_code=asg] button[data-value="4"] span,
body[d-broadcaster_code=asg] button[data-value="5"] span,
body[d-broadcaster_code=asg] button[data-value="6"] span,
body[d-broadcaster_code=asg] button[data-value="7"] span,
body[d-broadcaster_code=asg] button[data-value="8"] span,
body[d-broadcaster_code=asg] button[data-value="9"] span {
  background: #66b1d7 !important;
  color: #fecb00 !important
}

body[d-broadcaster_code=jokers] form label,
body[d-broadcaster_code=jokers] p {
  color: var(--primary-color, #666) !important
}

body {
  background-image: var(--background-image) !important;
  background-attachment: fixed;
  display: block;
  padding: 0;
}

@media (max-width:768px) {
  body {
    background-image: var(--mobile-image) !important;
  }
}

body header {
  background: 0 0
}

h1 {
  text-align: center;
  display: block;
  margin: 0 auto
}

.col1 {
  height: 90%;
  background: 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
  background-color: rgba(255, 255, 255, .25);
  -webkit-transition: background-image .5s linear;
  transition: background-image .5s linear
}

.col2 {
  color: #fff
}

body .col1 h1 {
  display: none
}

header {
  min-height: 13.5vh;
  padding: 30px
}

.intro {
  width: calc(100% / 3);
  float: left
}

.dropzone {
  background: 0 0 !important;
  border: none !important;
  padding: 0 !important;
  min-height: auto !important
}

.dropzone-filename {
  padding-top: 10px
}

#partner_intro {
  margin: 0 auto;
  margin-top: 5%;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase
}

.responsive-image {
  text-align: center;
  margin: 0 auto
}

.content.image {
  padding: 0;
  display: block
}

.author {
  font-size: 2vw;
  line-height: 2vw;
  text-transform: uppercase;
  text-align: right;
  margin: 0 auto;
  padding: 15px;
  padding-top: 20px;
  float: right;
  background: var(--secondary-color);
  color: var(--third-color);
  box-shadow: 5px 5px 5px var(--background-color)
}

body .col1,
body .col2,
body.form .form {
  display: block;
  margin: 0 auto;
  text-align: center
}

body .form,
body.form .col1,
body.form .col2 {
  display: none
}

#confirmation,
#error {
  display: none;
  text-align: center
}

button[disabled] {
  opacity: .2
}

form a {
  text-align: center;
  display: block;
  margin-top: 20px;
  text-transform: uppercase;
  color: var(--secondary-color) !important;
  margin: 1rem auto 1rem auto !important;
  font-weight: 700;
  border-radius: .4em;
  border-style: none;
  text-decoration: none;
  padding: .25rem;
  margin-top: 0 !important;
  margin-bottom: .25rem !important;
  text-decoration: underline !important
}

form #switcher a {
  background: 0 0 !important
}

input,
select,
textarea {
  font-family: Oswald, Arial, sans-serif
}

a.back {
  flex: unset;
  margin-bottom: 2rem
}

.dropzone .dz-message .dz-button {
  background: 0 0 !important;
  font-size: 1.5em;
  line-height: 1.5em;
  margin-bottom: .5em;
  text-transform: uppercase;
  color: var(--primary-color) !important;
  background: var(--fourth-color) !important
}

.dropzone .dropzone-select {
  color: var(--primary-color) !important;
  background: var(--fourth-color) !important;
  font-size: 1.5em;
  line-height: 1.5em;
  margin-bottom: .5em;
  text-transform: uppercase;
  width: 90% !important;
  margin: 0 auto !important;
  border-radius: .4em;
  padding-top: 5px;
  box-shadow: 3px 1px 2px var(--background-color, #000)
}

.dropzone .dz-message .dz-button {
  display: none !important
}

.main {
  height: 95vh;
  padding: 0 1.4rem
}

@media (max-width:768px) {
  body header {
    padding: unset;
    width: 100vw !important;
    flex: none !important
  }

  body .col2 {
    position: absolute;
    top: 700px;
    width: 90%
  }

  .pagination {
    z-index: 9999;
    position: sticky;
    margin: 0 auto;
    padding: 20px;
    padding-top: 40px;
    width: 100%
  }

  .pagination a {
    text-align: center;
    display: block;
    margin-top: 20px;
    color: var(--secondary-color) !important;
    text-transform: uppercase;
    margin: 1rem auto 1rem auto !important;
    text-decoration: none;
    padding: .25rem;
    margin-top: 0 !important;
    margin-bottom: .25rem !important;
    text-decoration: underline !important;
    font-weight: 700
  }

  a.previous-post {
    float: left
  }

  a.next-post {
    float: right
  }

  header .logo {
    display: block;
    max-width: 350px;
    max-height: 350px;
    margin: auto;
    margin-bottom: 50px
  }

  #header_logo.logo {
    padding-top: 50px !important
  }

  body[d-broadcaster_code=fiba] header h1 {
    font-size: 1.75rem
  }

  header a.right {
    display: block;
    text-align: center;
    margin: 0 auto;
    position: relative;
    width: unset;
    left: unset;
    right: unset;
    max-width: 100%
  }

  .post {
    width: 100%
  }

  .content {
    font-size: 12vw;
    line-height: 12vw;
    font-family: "Titillium Web", Oswald, sans-serif;
    color: var(--sixth-color);
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    margin: 0
  }

  h1.flex {
    display: none
  }

  h1.mobile-title {
    display: block;
    min-height: 50px;
    padding-top: 0 !important;
    margin-top: 0 !important
  }

  .author {
    font-size: 4vw;
    line-height: 3vw
  }
}

@media (min-width:768px) {
  .pagination {
    display: none
  }

  .container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%
  }

  .col1,
  .col2 {
    float: left;
    width: 47%
  }

  .col1 {
    margin: 20px 20px 0 20px;
    background-position: top center
  }

  .col1.landscape {
    width: 100%
  }

  .col2.landscape {
    width: 75%
  }

  .col2.landscape .container {
    position: absolute;
    top: 75%;
    transform: translateY(-50%);
    width: 50%;
    right: 25%
  }

  .col2 {
    height: 100%;
    float: left
  }

  .content {
    font-size: 3.5vw;
    line-height: 3.5vw;
    font-family: "Titillium Web", Oswald, sans-serif;
    color: var(--sixth-color);
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    margin: 0
  }

  body.form .form {
    max-width: 40vw;
    margin: 0 auto;
    padding-bottom: 1rem !important
  }

  h1.flex {
    display: block;
    font-size: 2.25rem;
    line-height: 2.25rem
  }

  h1.mobile-title {
    display: none
  }

  .disclaimer label,
  .dropzone .dropzone-select,
  form a,
  form button,
  form input,
  form label,
  form textarea {
    font-size: 1rem;
    line-height: 1rem
  }
}

body .form {
  padding-bottom: 50px
}

body[d-broadcaster_code=bcl] .container .content,
body[d-broadcaster_code=bcl] form button {
  font-family: Fortima, Oswald, sans-serif
}

#qrcode2 {
  position: absolute;
  width: 200px;
  height: 200px;
  right: 10px;
  bottom: 10px;
  border-radius: 20px;
  display: none;
  background: var(--background-color);
  padding: 10px;
  z-index: 99999999
}

@media (max-width:960px) {
  #qrcode2 {
    display: none
  }
}

form .label,
form label {
  font-size: 1rem !important;
  line-height: 1.25rem !important
}

.Toastify__toast {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-family: var(--secondary-font-family);
  color: var(--primary-color);
  text-transform: uppercase;
}

.Toastify__toast-container--top-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

svg:hover {
  cursor: pointer;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

#qrcode {
  background-image: var(--qrcode-image);
  margin: 0 auto;
  text-align: center;
  display: block;
  width: 100%;
  height: 70vh;
  background-size: 58vh;
  background-repeat: no-repeat;
  background-position: top;
}

body #header_logo,
body #header_logo2,
body #qrcode2 {
  display: none;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 0.35rem !important;
}

.css-i4bv87-MuiSvgIcon-root {
  display: block !important;
}

.MuiBox-root a {
  display: block;
  color: var(--secondary-color);
}

div[mode="fullscreen"] div {
  display: none;
}